<div class="nm-booking">
  <nm-layout
    title="Бронирование столика"
    icon="burgerMenu"
    [showImage]="(bookingService.isDone$ | async) === false"
    [storeName]="(bookingService.booking$ | async)?.storeName || ''"
    [storeDescription]="(bookingService.booking$ | async)?.bookingDescription || ''"
  ></nm-layout>

  <div class="booking" *ngIf="!!(bookingService.schedules$ | async) && !!(bookingService.booking$ | async)">
    <ng-container *ngIf="(bookingService.isDone$ | async) === false">
      <nm-chips
        ngDefaultControl
        label="Дата"
        [values]="bookingService.dateValues$ | async"
        [control]="date"
        [required]="true"
      ></nm-chips>

      <nm-chips
        ngDefaultControl
        label="Время"
        [values]="bookingService.timeValues$ | async"
        [control]="time"
        [required]="true"
      ></nm-chips>
      <nm-chips ngDefaultControl label="Количество гостей" [values]="countPeople" [control]="count"></nm-chips>

      <nm-input
        #phoneInput
        ngDefaultControl
        label="Телефон"
        placeholder="+ 7 (000) 000-00-00"
        [formControl]="phone"
        [required]="true"
        [maxLength]="maxCharacters.PHONE"
        [error]="validationErrorsService.isControlDirtyAndInvalid(phone)"
        [hint]="validationErrorsService.validateField(phone) || phone.getError('phone')"
        (input)="phoneChange(phoneInput.inputElem?.nativeElement)"
      ></nm-input>

      <nm-input
        ngDefaultControl
        label="Имя"
        placeholder="Иван Иванов"
        [formControl]="name"
        [required]="true"
        [maxLength]="maxCharacters.NAME"
        [error]="validationErrorsService.isControlDirtyAndInvalid(name)"
        [hint]="validationErrorsService.validateField(name) || name.getError('name')"
      ></nm-input>

      <nm-input
        ngDefaultControl
        label="Комментарий"
        placeholder="Пожелания по бронированию"
        [formControl]="comment"
        [maxLength]="maxCharacters.DESCRIPTION"
        [error]="validationErrorsService.isControlDirtyAndInvalid(comment)"
        [hint]="validationErrorsService.validateField(comment) || comment.getError('comment')"
      ></nm-input>

      <nm-button
        variant="filled"
        [fullWidth]="true"
        (clickButton)="bookingService.submitForm()"
        [loading]="(bookingService.isSubmitDisabled$ | async) === true"
        >Подтвердить бронирование</nm-button
      >

      <div class="logo-block">
        <div class="logo-text">Работает на</div>
        <img [src]="'/assets/images/logo.svg'" alt="logo" />
      </div>
    </ng-container>

    <ng-container *ngIf="(bookingService.isDone$ | async) === true">
      <div class="booking-done">
        <div class="booking-banner">
          <img id="icon" [src]="getIconUrl()" alt="icon" />
          <div class="booking-banner-desc">
            {{ (bookingService.booking$ | async)?.acceptanceTitle || 'Бронирование подтверждено' }}
          </div>
          <div class="booking-banner-title">
            {{ (bookingService.booking$ | async)?.acceptanceDescription || 'Спасибо!' }}
          </div>
        </div>
        <div class="booking-infos">
          <div class="booking-title">Данные бронирования</div>
          <ng-container *ngFor="let info of bookingService.bookingInfo | async">
            <div class="booking-info" *ngIf="info.text">
              <div class="booking-text">{{ info.title }}</div>
              <div class="booking-text booking-desc">{{ info.text }}</div>
            </div>
          </ng-container>
        </div>
        <!--      <nm-button variant="tonal" [fullWidth]="true">Меню ресторана</nm-button>-->
      </div>

      <div class="logo-block">
        <div class="logo-text">Работает на</div>
        <img [src]="'/assets/images/logo.svg'" alt="logo" />
      </div>
    </ng-container>
  </div>
</div>
