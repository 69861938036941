import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';

import { BookingService } from '@app/modules/services/booking/booking.service';
import { InputComponent } from '@app/shared/component/input/input.component';
import { MAX_CHARACTERS, NOT_PHONE_NUMBER_SPECIFIC_CHARS } from '@constants';
import { ValidationErrorsService } from '@core';
import { ImagesService } from '@shared';

export type BookingInfo = {
  title: string;
  text: string;
};

@Component({
  selector: 'nm-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingComponent {
  maxCharacters = MAX_CHARACTERS;
  countPeople = Array.from({ length: 30 }, (_, i) => ({ value: String(i + 1) }));

  get controls() {
    return this.bookingService.form.controls;
  }

  get name() {
    return this.controls.name;
  }

  get comment() {
    return this.controls.comment;
  }

  get phone() {
    return this.controls.phone;
  }

  get date() {
    return this.controls.date;
  }

  get time() {
    return this.controls.time;
  }

  get count() {
    return this.controls.count;
  }

  constructor(
    public bookingService: BookingService,
    public validationErrorsService: ValidationErrorsService,
    public imagesService: ImagesService,
    private sanitizer: DomSanitizer,
    private title: Title,
  ) {
    this.title.setTitle('Бронирование столика');
  }

  phoneChange(input: InputComponent): void {
    if (input && input.value) {
      const replaced: string = input.value.toString().replace(NOT_PHONE_NUMBER_SPECIFIC_CHARS, '');
      this.phone.setValue(replaced);
    }
  }

  getIconUrl() {
    if (this.imagesService.file2 && Array.isArray(this.imagesService.file2)) {
      return (this.imagesService.file2 as [SafeUrl | string, string])[0];
    }

    if (this.imagesService.file2 && this.imagesService.file2 instanceof File) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imagesService.file2));
    }
    return './assets/images/checkmark-circle.png';
  }
}
